<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/lubricaOilManage' }">润滑油档案</el-breadcrumb-item>
        <el-breadcrumb-item>新增档案</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <div class="form-title">基本信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="型号" prop="model">
              <el-input v-model="form.model" placeholder="请输入型号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编码" prop="code">
              <el-input v-model="form.code" placeholder="请输入编码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="品牌" prop="brandId">
              <el-select v-model="form.brandId" placeholder="请选择品牌">
                <el-option label="无" value=""></el-option>
                <el-option
                  v-for="item in brand"
                  :key="item.id"
                  :label="item.brand"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格参数" prop="specification">
              <el-input v-model="form.specification" placeholder="请输入规格参数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="分类" prop="classificationId">
              <el-select v-model="form.classificationId" placeholder="请选择分类">
                <el-option label="无" value=""></el-option>
                <el-option
                  v-for="item in classification"
                  :key="item.id"
                  :label="item.classification"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="等级" prop="levelId">
              <el-select v-model="form.levelId" placeholder="请选择等级">
                <el-option label="无" value=""></el-option>
                <el-option
                  v-for="item in level"
                  :key="item.id"
                  :label="item.level"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="生产厂家" prop="manufacturer">
              <el-input v-model="form.manufacturer" placeholder="请输入生产厂家"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其他描述">
              <el-input v-model="form.otherDesc" placeholder="请输入其他描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-title">管理信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属部门" prop="departmentId">
              <el-select v-model="form.departmentId" placeholder="请选择所属部门">
                <el-option label="无" value=""></el-option>
                <el-option
                  v-for="item in bumen"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人" prop="staffId">
              <el-select v-model="form.staffId" placeholder="请选择负责人">
                <el-option label="无" value=""></el-option>
                <el-option
                  v-for="item in yuangong"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        
        <div class="form-title">设备图片</div>
        <el-upload
          action="#"
          list-type="picture-card"
          :file-list="allFileList"
          :on-change="uploadChange"
          :auto-upload="false">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url" />
            <span class="el-upload-list__item-actions">
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <div class="prompt">注意：图片尺寸800*800px，大小≤2MB，支持JPG、PNG、JPEG，最多上传5张</div>
        <div class="bottom-btn">
          <el-button type="primary" size="small" @click="$router.push('/lubricaOilManage')">取消</el-button>
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        model:[{ required: true, message: '必填项', trigger: 'blur' }],
        code:[{ required: true, message: '必填项', trigger: 'blur' }],
        brandId:[{ required: true, message: '必填项', trigger: 'blur' }],
        specification:[{ required: true, message: '必填项', trigger: 'blur' }],
        classificationId:[{ required: true, message: '必填项', trigger: 'blur' }],
        levelId:[{ required: true, message: '必填项', trigger: 'blur' }],
        manufacturer:[{ required: true, message: '必填项', trigger: 'blur' }],
        otherDesc:[{ required: true, message: '必填项', trigger: 'blur' }],
        staffId:[{ required: true, message: '必填项', trigger: 'blur' }],
        departmentId:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      disabled: false,
      allFileList: [],
      brand: [],
      classification: [],
      level: [],
      bumen: [],
      yuangong: [],
    };
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("model", this.form.model);
          formData.append("code", this.form.code);
          formData.append("brandId", this.form.brandId);
          formData.append("specification", this.form.specification);
          formData.append("classificationId", this.form.classificationId);
          formData.append("levelId", this.form.levelId);
          formData.append("factory", this.form.manufacturer);
          formData.append("description", this.form.otherDesc ? this.form.otherDesc : null);
          formData.append("departmentId", this.form.departmentId);
          formData.append("staffId", this.form.staffId);

          let department = this.bumen.find((item)=>{
            return item.id == this.form.departmentId
          })
          let staff = this.yuangong.find((item)=>{
            return item.id == this.form.staffId
          })
          formData.append("department", department.name);
          formData.append("staffName", staff.name);
          this.allFileList.forEach((item) => {
            formData.append("files", item.raw);
          });
          this.loading = true;
          this.$ajax.post("lubeManagerCreate", formData).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/lubricaOilManage");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    uploadChange(file, fileList) {
      this.allFileList = fileList;
      console.log(fileList);
    },
    handleRemove(file) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allFileList = this.allFileList.filter(checkAdult);

      console.log(this.allFileList);
    },
    load_brand() {
      this.$ajax.post("lubeBrand", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.brand = res.data
      });
    },
    load_classification() {
      this.$ajax.post("lubeClassification", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.classification = res.data
      })
    },
    load_level() {
      this.$ajax.post("lubeLevel", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.level = res.data
      })
    },
    load_bumen() {
      this.$ajax.get("departmentTree").then((res) => {
        this.bumen = this.getTreeData(res.data);
      });
    },
    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
  },
  mounted() {
    this.load_brand();
    this.load_classification();
    this.load_level();
    this.load_bumen();
    this.load_yuangong();
  },
};
</script>

<style lang="less" scoped>
.mains {
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin-top: 10px;
}
.el-select {
  width: 100%;
}
</style>